<template>
    <label class="form-radio flex items-start cursor-pointer">
        <div class="flex items-center h-5">
            <input v-bind="attributes" v-on="listeners">
        </div>

        <div v-if="$slots && ($slots['label'] || $slots['description'])" class="ml-3">

            <div v-if="$slots && $slots['label']" :for="$attrs.name" class="font-medium text-gray-700">
                <slot name="label" />
            </div>

            <p v-if="$slots && $slots['description']" class="text-gray-500">
                <slot name="description" />
            </p>

        </div>

    </label>
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: () => false,
        },
    },
    data() {
        return {
            baseClasses: "focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-200",
            disabledClasses: "opacity-75 cursor-not-allowed",
        };
    },
    computed: {
        classes() {
            return `${this.baseClasses} ${this.disabled ? this.disabledClasses : ""}`;
        },
        attributes() {
            return {
                class: this.classes,
                type: "radio",
                ...this.$attrs,
            };
        },
        listeners() {
            return {
                ...this.$listeners,
            };
        },
    },
};
</script>
